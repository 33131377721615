.MuiFab-primary {
    display: none;
    color: #FFF;
    background-color: #00695c;
}

.MuiStepIcon-root {
    color: gray;
    display: block;
}


/* Css 14 Sep 2020 */

.top-icons {
    display: block;
    width: 100%;
    margin-bottom: 40px;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.language-btn {
    padding: 0px;
}

.home-icon {
    color: #22574c;
}


/* .loginBg{
  background-image: url(../src/assets/images/LoginBackground.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  /* background-position: center center;
  background-position: center left;
  height: 100%;
  width: 100%;
  padding: 10% 0;
  position: fixed;
} */


/* Sidebar */

.logo-container {
    display: block;
    height: 230px;
    text-align: center;
}

.logo-div {
    margin: 0 auto;
    text-align: center;
}

.logo-div img {
    width: 100px;
    min-width: 30px;
}

.btn-width-full {
    width: 150px;
    padding: 7px 22px;
    /*13 22px*/
}


/* dropdown height */

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding-right: 45px;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 5px 9px;
}

.MuiFormLabel-root {
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    font-size: 14px;
    font-family: Nunito, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 400;
    line-height: 0;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
    padding: 7px 4px;
}


/*.MuiTablePagination-caption{*/


/*  display: none;*/


/*}*/

.circle-icon {
    text-align: center;
    width: 1.5em;
    height: 1.5em;
    display: inline-block;
    border-radius: 50%;
    color: #fff;
    background: #00695c;
    float: right;
}


/* Small devices (portrait tablets and large phones, 600px and up) */

@media screen and (max-width: 600px) {
    .header-title {
        float: left;
        color: #00695c;
        font-size: 12px;
    }
}

.header-title {
    float: left;
    color: #00695c;
    font-size: 16px;
    font-weight: bold;
}

.stat-count .title {
    color: #00796b;
}


/* Sidebar shadow to deff top and bottom menu section and height of container */

.logo-container {
    box-shadow: inset 0px -2px 5px 0px #2222226e;
    height: 210px;
}


/* decreased size of sidebar logo */

.logo-container .logo-div img {
    width: 90px;
    min-width: 30px;
}


/* Sidebar top padding removed */

.scrollbar-container .bKPSQv,
.scrollbar-container .bSNWGL {
    padding-top: 0px;
    padding-bottom: 10px;
}


/* laptop view */

@media only screen and (min-width: 768px) {
    .header-title {
        padding-left: 30px;
    }
}


/* desktopView */

@media screen and (min-width: 1370px) and (max-width: 1605px) {
    .header-title {
        padding-left: 30px;
    }
}

@media (min-width: 960px) {
    .eFuXUm {
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}

@media (min-width: 960px) {
    .signin {
        width: 40vw;
        margin: 0;
        position: absolute;
        top: 50%;
        left: 30%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}

.loginBg {
    background-image: url(../src/assets/images/LoginBackground2.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    /* background-position: center center; */
    background-position: center left;
    height: 100%;
    width: 100%;
    padding: 10% 0;
    position: fixed;
}


/* Table Header padding Reduced */


/*.MuiCardHeader-root {*/


/*  padding: 16px 16px 16px 0px;*/


/*}*/


/* Input type textbox height */

.MuiOutlinedInput-input {
    padding: 13px 14px;
}


/* Margin top */

.mt-20 {
    margin-top: 20px;
}

.mt-15 {
    margin-top: 15px;
}


/*.MuiChip-deleteIconColorPrimary  {*/


/*  color: #808080;*/


/*}*/

.MuiChip-deleteIconColorPrimary:hover,
.MuiChip-deleteIconColorPrimary:active {
    color: #000000cf;
}

.MuiChip-deletableColorPrimary:focus {
    background-color: #ffa500;
}

.dsABBg {
    margin-right: 10px;
}

@media only screen and (max-width: 600px) {
    .dsABBg {
        margin-bottom: 10px;
    }
}


/* identify details */

.title-align {
    padding-top: 10px;
}

.MuiLink-underlineHover {
    float: right;
    margin-right: 150px;
}


/* icon  button **/

.iconBtn {
    margin-top: 15px;
    /* margin-right: 15px; */
    font-size: 23px !important;
    float: right;
}


/* note for Verification Table */

.note {
    text-align: end;
    padding-right: 16px;
}

@media (max-width: 480px) {
    .note {
        text-align: start;
        padding-right: 16px;
    }
}

.change-language {
    padding: 0px 8px;
}

.iconBtn {
    margin-top: 16px;
    /* margin-right: 16px; */
    float: right;
    width: 30px;
    height: 30px;
}